<template>
  <div>
    <v-row
      no-gutters
      align="baseline"
    >
      <v-menu
        v-if="document.creditHoldReason"
        v-model="showTooltip"
        offset-y
      >
        <template v-slot:activator="{  }">
          <v-btn
            class="mr-1"
            fab
            icon
            small
            @click="showTooltip = !showTooltip"
          >
            <v-icon color="warning">{{$icon('i.AlertOctagon')}}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>
              <v-col>
                <span>{{$t('t.OnCreditHold')}}</span>
                <v-icon>{{$icon('i.ChevronRight')}}</v-icon>
                <span>{{document.creditHoldReason.name}}</span>
              </v-col>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <account-ref
        :id="document.id"
        emphasis
      />
      <span class="business-identifier mx-2">{{document.businessIdentifier}}</span>
      <v-tooltip
        v-if="creditHoldReason"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-chip
            v-on="on"
            small
            outlined
            color="red"
            :style="!isFramed ? undefined : 'background-color: white !important;'"
          >
            <document-name-ref
              :id="creditHoldReason.id"
              :cache-type="$cacheTypeFromString(creditHoldReason.type)"
              color="red"
            />
          </v-chip>
        </template>
        {{$t('t.CreditHoldReasons')}}
      </v-tooltip>
    </v-row>
  </div>
</template>

<script>
import themeController from '@/themeController'

export default {
  components: {
    AccountRef: () => import('@/components/documents-ref/account-ref'),
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref')
  },
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showTooltip: false,
      showActions: false
    }
  },
  computed: {
    creditHoldReason () {
      if (!this.document?.creditHoldReasonId) { return }
      return { type: 'credit-hold-reasons', id: this.document?.creditHoldReasonId }
    },
    isFramed () {
      return themeController.isFramed
    }
  }
}
</script>

<style lang="stylus" scoped>
.business-identifier
  font-size 16px

>>> .v-icon
  color inherit
</style>
